import React from "react";
import { Redirect } from "react-router-dom";
import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";

const entrypoint = process.env.API_URL;

const fetchHeaders = () =>
  localStorage.getItem("token") !== null
    ? {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    : {};

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders())
  });

const apiDocumentationParser = entrypoint =>
  parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders())
  }).then(
    ({ api }) => ({ api }),
    result => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              {
                props: {
                  path: "/",
                  render: () => <Redirect to={"/login"} />
                }
              }
            ]
          });

        default:
          return Promise.reject(result);
      }
    }
  );
const dataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser
);

export { dataProvider, apiDocumentationParser };
